import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import {
  useContentfulSection,
  useMeta,
  useContentfulComponent,
  useRichText,
  device,
} from "utils"

const TermsOfService = ({ data: { tos } }) => {
  const { terms_of_service } = useContentfulSection(tos.sections)

  const { terms_of_service_doc } = useContentfulComponent(
    terms_of_service.components
  )

  const text = useRichText({ text: terms_of_service_doc.body })

  const meta = useMeta(tos)
  return (
    <Layout>
      <Seo {...meta} />
      <Nav />

      <Wrap>{text}</Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  padding: 0 var(--sp-32);
  padding-top: var(--sp-mobile-nav);
  max-width: 900px;
  margin: 0 auto;
  @media ${device.laptop} {
    padding-top: var(--sp-nav);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  a {
    /* white-space: break-spaces; */
    color: var(--primary-font-color);
  }

  h1 {
    margin-bottom: var(--sp-24);
  }

  h2,
  h3,
  h4 {
    margin-bottom: var(--sp-8);
  }
  p {
    white-space: break-spaces;
    margin-bottom: var(--sp-24);
  }
`

export const query = graphql`
  {
    tos: contentfulPage(pageId: { eq: "terms_of_service" }) {
      ...Page
    }
  }
`

export default TermsOfService
